import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import LuxurySection from "../components/luxury"
import { graphql } from "gatsby"
const LuxuryDesign = () => {
  return (
    <Layout>
      <Seo title="Luxury design for your home lift" />
      <LuxurySection />
    </Layout>
  )
}

export default LuxuryDesign

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
