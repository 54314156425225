import React from "react"
import LuxuryHero from "./hero"
import Options from "./options"

const LuxurySection = () => {
  return (
    <>
      <LuxuryHero />
      <Options />
    </>
  )
}

export default LuxurySection
