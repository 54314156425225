import React from "react"

import { StaticImage } from "gatsby-plugin-image"
import InnerContainer from "../../styles/styled-component/InnerContainer"
import Section from "../../styles/styled-component/Section"
import Container from "../../styles/styled-component/Container"
import { LuxurySection } from "./luxuryComponents"
import bgImg from "../../images/luxury-bg.jpg"

import { Trans } from "gatsby-plugin-react-i18next"
const LuxuryHero = () => {
  return (
    <LuxurySection flex bgImage={bgImg}>
      <Container>
        <InnerContainer>
          <h1>
            <Trans>The designer Elevator your were looking for</Trans>
          </h1>
          <p>
            <Trans>
              Learn about Divas Luxury design options, made by artisans with
              valuable handmade materials for Architects and interior designers
              looking to match the home elevator looks with your home
            </Trans>
          </p>
        </InnerContainer>
      </Container>
    </LuxurySection>
  )
}

export default LuxuryHero
