import React from "react"
import Container from "../../styles/styled-component/Container"
import InnerContainer from "../../styles/styled-component/InnerContainer"
import Section from "../../styles/styled-component/Section"
import Button, { ButtonWrapper } from "../../styles/styled-component/Button"
import { StaticImage } from "gatsby-plugin-image"
import { Trans } from "gatsby-plugin-react-i18next"
const Options = () => {
  return (
    <Section>
      <Container flex center>
        <InnerContainer half>
          <h2>
            <Trans>Bespoke design solutions</Trans>
          </h2>
          <p>
            <Trans>
              Are you an interior designer or architect looking to provide your
              building with an high quality lift with a matching style to your
              elevator?
            </Trans>
          </p>
          <p>
            <Trans>
              Contact our experienced sales team, we'll collaborate to provide
              you a solution for your designer needs or get inspired by other
              possible solutions below
            </Trans>
          </p>
          <ButtonWrapper>
            <Button bgColor="#0066ff" borderRadius="50px" to="/contact">
              <Trans>Contact Sales</Trans>
            </Button>
          </ButtonWrapper>
        </InnerContainer>
        <InnerContainer half>
          <StaticImage
            src="../../images/inside-elevator.png"
            placeholder="blurred"
          />
        </InnerContainer>
      </Container>
      <Container flex center>
        <InnerContainer half>
          <StaticImage
            src="../../images/marble-finish.png"
            placeholder="blurred"
          />
        </InnerContainer>
        <InnerContainer half>
          <h2>
            <Trans>Marble finish</Trans>
          </h2>
          <p>
            <Trans>
              Give your divas lift a elegant and minimal style, using high
              quality italian marble, cut and fitted by expert artisans
            </Trans>{" "}
          </p>
        </InnerContainer>
      </Container>{" "}
      <Container flex center>
        <InnerContainer half>
          <h2>
            <Trans>Wood finish</Trans>
          </h2>
          <p>
            <Trans>
              A warm and stylish solution, inspired by the beautiful wood inlays
              from Sorrento's coast, our local artisans can provide unique
              solutions to decorate your home lift with beautiful wood inlays
              coming from a century long tradition
            </Trans>
          </p>
        </InnerContainer>{" "}
        <InnerContainer half>
          <StaticImage
            src="../../images/wood-finish.png"
            placeholder="blurred"
          />
        </InnerContainer>
      </Container>{" "}
      <Container flex center>
        <InnerContainer half>
          <StaticImage
            src="../../images/leather-finish.png"
            placeholder="blurred"
          />
        </InnerContainer>
        <InnerContainer half>
          <h2>
            <Trans>Leather finish</Trans>
          </h2>
          <p>
            <Trans>
              Fit your Divas lift with high quality leather finish, inspired by
              the Italian furniture tradition, give your home lift a soft and
              exclusive look
            </Trans>
          </p>
        </InnerContainer>
      </Container>
    </Section>
  )
}

export default Options
